'use strict';

let $ = window.$ || window.jQuery || require('jquery');
const WebFont = require('webfontloader');

document.addEventListener('integration:feature-flag-provider', (event) => {
    event.detail.registrar.register({
        site: 'SN',
        'analytics.gtm.active': true,
        'voyado.tracker.active': false, // TODO Can be re-verified, but so far no Voyado code found on SN.
        'cart.active': false, // Disables functionality for analytics (and other cart-related functionality).
        'checkout.klarna.active': false,
        'checkout.klarna.instantshopping.active': false,
        'checkout.klarna.instantshopping.style.variation': '',
        'checkout.klarna.instantshopping.style.type': '',
        'customer.registration.enabled': false,
        'customer.registration.registrationTabFlag': false,
        'integration.gmf.active': false
    });

    event.detail.registrar.callback((modules) => {
        const WebFontConfig = {
            custom: {
                families: ['bodystore-icons'], // Why on SN are loaded bodystore icons?
                urls: [window.staticURL + 'css/fonts.css']
            },
            active: () => modules()
        };

        /**
         * Wait font to load before initialization of JS modules
         */
        $(document).ready(() => {
            WebFont && typeof WebFont.load === 'function' ? WebFont.load(WebFontConfig) : modules();
        });
    });
});
